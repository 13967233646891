import { ParsedUrlQuery } from "querystring"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import type { GetServerSideProps, InferGetServerSidePropsType } from "next"
import Head from "next/head"
import Image from "next/image"
import { useRouter } from "next/router"
import { Button } from "@components/common/Button"
import { Center } from "@components/common/Center"
import { LoadingSpinner } from "@components/common/LoadingSpinner"
import { MilestoneStepsProgress } from "@components/common/MilestoneStepsProgress"
import { FakeProgressBar } from "@components/common/ProgressBar"
import SurveyHeader from "@components/common/SurveyHeader"
import { withPayment } from "@contexts/payment"
import { withSubscription } from "@contexts/subscription"
import {
  externalFunnels,
  Funnel,
  InsuranceFunnel,
  insuranceFunnels,
  SurveyConfig,
  SurveyExperiments,
  SurveyStateInitialized,
  SurveyTheme,
  surveyThemes,
  validFunnels,
} from "@contexts/survey/survey"
import { setStatsigClient, trackScreenView } from "@services/client/events"
import { initFacebookPixel } from "@services/client/tracking"
import { getStatsigServerProps, StatsigServerProps } from "@services/server/statsig"
import { LogLevel, StatsigClient, StatsigProvider } from "@statsig/react-bindings"
import { isDev } from "@utils/checkEnv"
import classNames from "classnames"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"

import { SelectStep } from "../../components/steps/SelectStep"
import getSurveyExternal from "../../components/steps/surveyConfigExternal"
import getSurveyConfigInsurance from "../../components/steps/surveyConfigInsurance"
import getSurveyConfigV2 from "../../components/steps/surveyConfigV2"
import { useAuth } from "../../contexts/auth"
import { signInAnonymously, User } from "../../contexts/auth/auth"
import { Answers, loadSurvey, QuestionProvider, useSurvey } from "../../contexts/survey"
import { includeDefaultNamespaces, useTranslation } from "../../i18n"
import { setAmplitudeUserId, setAmplitudeUserProperty } from "../../services/client/amplitude"
import { convertFeetInchesToCm, convertLbsToKg } from "../../utils/convert"

const getSurveyTheme = (query: ParsedUrlQuery): SurveyTheme => {
  const q = ((query["theme"] as string) ?? "").toLowerCase().trim()

  return surveyThemes.includes(q as SurveyTheme) ? (q as SurveyTheme) : "fasting"
}

const externalFunnelFromURL = (query: ParsedUrlQuery): Funnel | undefined => {
  const q = ((query["funnel"] as string) ?? "").toLowerCase().trim()
  return validFunnels.includes(q as any) ? (q as Funnel) : undefined
}

const getGenderFromTheme = (theme: SurveyTheme): string | undefined => {
  if (theme.includes("men")) return "male"
  if (theme.includes("women") || theme.includes("woman")) return "female"
  return undefined
}

const getDataFromURL = (query: ParsedUrlQuery): Record<string, string | undefined> => {
  return {
    weight: (query["weight"] as string) || undefined,
    targetWeight: (query["target_weight"] as string) || undefined,
    age: (query["age"] as string) || undefined,
    height: (query["height"] as string) || undefined,
    gender: (query["gender"] as string) || undefined,
    goal: (query["goal"] as string) || undefined,
    measurementSystem: (query["measurement_system"] as string) || undefined,
  }
}

const getUTMParams = (query: ParsedUrlQuery): Record<string, string | undefined> => {
  return {
    utm_ad: query["utm_ad"] as string,
    utm_adset: query["utm_adset"] as string,
    utm_campaign: query["utm_campaign"] as string,
    utm_source: query["utm_source"] as string,
  }
}

type Geo = {
  country: string
  currencyCode: string
}

type PageConfig = {
  fbPixelId: string
}

const Survey: React.FC<InferGetServerSidePropsType<typeof getServerSideProps>> = (props) => {
  const statsigClientRef = useRef<StatsigClient | null>()
  const router = useRouter()
  const [user, authInitialized] = useAuth()
  const [surveyState, dispatch] = useSurvey()
  const { t, i18n } = useTranslation()
  const [routerInitDone, setRouterInitDone] = useState<boolean>(false)
  const [isLoaderDone, setLoaderDone] = useState<boolean>(false)
  const initialStep = useRef<string>()
  const iniitalQueryParams = useRef({} as ParsedUrlQuery)
  const [surveyTheme, setSurveyTheme] = useState<SurveyTheme>("fasting")
  const [funnel, setFunnel] = useState<Funnel>()

  const statsigClient = useMemo(() => {
    if (!props.statsig) {
      return null
    }

    if (statsigClientRef.current) {
      return statsigClientRef.current
    }

    const { key, user, data } = props.statsig
    const inst = new StatsigClient(key, user, {
      logLevel: isDev() ? LogLevel.Debug : LogLevel.Error,
      environment: { tier: isDev() ? "development" : "production" },
    })
    statsigClientRef.current = inst

    inst.dataAdapter.setData(data)
    inst.initializeSync()

    setStatsigClient(inst)

    return inst
  }, [props.statsig, statsigClientRef.current])

  //These skip the loader
  const skipLoaderFunnels = ["no_loader", "funnelfox", "no_loader_track"]

  useEffect(() => {
    if (router.isReady) {
      const { step, ...initialQuery } = router.query
      initialStep.current = step as string
      iniitalQueryParams.current = initialQuery

      setSurveyTheme(getSurveyTheme(initialQuery))
      setFunnel(externalFunnelFromURL(initialQuery))

      initFacebookPixel({ pixelId: props.config.fbPixelId })

      setRouterInitDone(true)
    }
  }, [router.isReady])

  // load the user survey, pass already prefilled answers
  const initUserSurvey = async (user: User, experiments: SurveyExperiments) => {
    const answers = await loadSurvey(user.id)

    const gender = getGenderFromTheme(surveyTheme)

    const imperialCountries = ["US", "LR", "MM"]
    const updatedAnswers: Answers = {
      ...answers,
      country: props.geo.country,
      currency: props.geo.currencyCode,
      locale: i18n.language,
      measurement_system: imperialCountries.includes(props.geo.country) ? "imperial" : "metric",
      utc_offset: new Date().getTimezoneOffset() * -1,
      ...(gender && { gender: [gender] }),
    }

    // Convert and add data from URL
    const urlData = getDataFromURL(iniitalQueryParams.current)
    if (urlData.measurementSystem === "imperial") {
      if (urlData.weight) {
        updatedAnswers.weight = convertLbsToKg(parseFloat(urlData.weight))
      }
      if (urlData.targetWeight) {
        updatedAnswers.target_weight = convertLbsToKg(parseFloat(urlData.targetWeight))
      }
      if (urlData.height) {
        const [feet, inches] = urlData.height.includes("'")
          ? urlData.height.split("'").map(parseFloat)
          : urlData.height.split("-").map(parseFloat)
        updatedAnswers.height = convertFeetInchesToCm({ feet, inches })
      }
    } else {
      if (urlData.weight) updatedAnswers.weight = parseFloat(urlData.weight)
      if (urlData.targetWeight) updatedAnswers.target_weight = parseFloat(urlData.targetWeight)
      if (urlData.height) updatedAnswers.height = parseFloat(urlData.height)
    }

    if (urlData.age) updatedAnswers.age = parseInt(urlData.age)
    if (urlData.gender) updatedAnswers.gender = [urlData.gender]
    if (urlData.goal) updatedAnswers.goal = urlData.goal
    if (urlData.measurementSystem) updatedAnswers.measurement_system = urlData.measurementSystem

    const allParametersPresent = [
      "weight",
      "targetWeight",
      "age",
      "height",
      "gender",
      "goal",
      "measurementSystem",
    ].every((param) => urlData[param] !== undefined)

    const getSurveryConfig = (experiments: SurveyExperiments): SurveyConfig => {
      // check if it's a external funnel e.g from funnelfox or whatsapp
      if (externalFunnels.includes(funnel) && allParametersPresent) {
        return getSurveyExternal(experiments.insuranceWithYourProgram)
      }
      // check if it's a insurance funnel
      else if (insuranceFunnels.includes(funnel as InsuranceFunnel)) {
        return getSurveyConfigInsurance(
          surveyTheme,
          experiments.insuranceWithYourProgram,
          experiments.insuranceWithPersonalPlan,
          experiments.insuranceNewPaywallEnabled
        )
      }
      return getSurveyConfigV2(
        surveyTheme,
        funnel,
        experiments.insuranceWithYourProgram,
        experiments.insuranceWithPersonalPlan,
        experiments.insuranceNewPaywallEnabled
      )
    }

    const surveyConfig = getSurveryConfig(experiments)

    const shouldShowLanding = funnel === "no_loader" && !initialStep.current
    const initialPathToUse = shouldShowLanding ? "landing" : initialStep.current?.toString()

    dispatch({
      type: "SURVEY_LOADED",
      uid: user.id,
      theme: surveyTheme,
      funnel,
      experiments,
      config: surveyConfig,
      answers: updatedAnswers,
      initialPath: initialPathToUse,
    })
  }

  // wait for initial auth update
  useEffect(() => {
    if (user && routerInitDone && statsigClient) {
      const insuranceWithYourProgram = statsigClient.getExperiment("insurance_with_your_program").get("enabled", true)
      const insuranceWithPersonalPlan = statsigClient
        .getExperiment("insurance_with_personal_plan")
        .get("enabled", false)
      const insuranceNewPaywallEnabled = statsigClient
        .getExperiment("insurance_new_paywall_enabled")
        .get("enabled", false)
      //const insuranceFlowVariant = statsigClient.getExperiment("insurance_flow_variant").get("version", "v1")
      const insuranceFlowVariant = "v2"

      setAmplitudeUserId(user.id)
      initFacebookPixel({ pixelId: props.config.fbPixelId, userId: user.id })

      // Funnel and theme
      setAmplitudeUserProperty("funnel", funnel)
      setAmplitudeUserProperty("theme", surveyTheme)
      // Insurance experiments
      setAmplitudeUserProperty("exp_insurance_flow_variant", insuranceFlowVariant)
      setAmplitudeUserProperty("exp_insurance_with_your_program", insuranceWithYourProgram)
      setAmplitudeUserProperty("exp_insurance_with_personal_plan", insuranceWithPersonalPlan)
      setAmplitudeUserProperty("exp_insurance_new_paywall_enabled", insuranceNewPaywallEnabled)
      // UTM params
      const utmParams = getUTMParams(iniitalQueryParams.current)
      setAmplitudeUserProperty("utm_ad", utmParams.utm_ad)
      setAmplitudeUserProperty("utm_adset", utmParams.utm_adset)
      setAmplitudeUserProperty("utm_campaign", utmParams.utm_campaign)
      setAmplitudeUserProperty("utm_source", utmParams.utm_source)

      initUserSurvey(user, {
        insuranceWithYourProgram: insuranceWithYourProgram,
        insuranceWithPersonalPlan: insuranceWithPersonalPlan,
        insuranceNewPaywallEnabled: insuranceNewPaywallEnabled,
        insuranceFlowVariant: insuranceFlowVariant,
      })
    } else if (authInitialized && !user) {
      signInAnonymously()
    }
  }, [user?.id, authInitialized, routerInitDone, statsigClient])

  // track screen views
  useEffect(() => {
    if (surveyState.initialized && surveyState.currentQuestion) {
      trackScreenView(surveyState.currentQuestion.path)
    }
  }, [surveyState.initialized, (surveyState as SurveyStateInitialized).nextQuestion])

  // update the current route if question has changed
  useEffect(() => {
    if (surveyState.initialized && surveyState.prevQuestion?.path !== surveyState.currentQuestion?.path) {
      router.push(
        {
          pathname: "/survey/[[...step]]",
          query: { step: surveyState.currentQuestion.path, ...iniitalQueryParams.current },
        },
        undefined,
        { shallow: true }
      )
    }
  }, [
    surveyState.initialized,
    (surveyState as SurveyStateInitialized).currentQuestion,
    (surveyState as SurveyStateInitialized).prevQuestion,
  ])

  const handleRouteChange = useCallback(
    (url: string) => {
      if (
        url.includes("/survey/") &&
        surveyState.initialized &&
        surveyState.currentQuestion &&
        !(url as string).includes(surveyState.currentQuestion.path)
      ) {
        let requestedStep = (url as string).substring((url as string).lastIndexOf("/") + 1)
        if (requestedStep.includes("?")) {
          requestedStep = requestedStep.substring(0, requestedStep.indexOf("?"))
        }
        const questionToSet = surveyState.config.questions.findIndex((q) => q.path === requestedStep)
        if (questionToSet >= 0) {
          dispatch({
            type: "SET_QUESTION",
            path: surveyState.config.questions[questionToSet].path,
          })
        }
      }
    },
    [surveyState.initialized, (surveyState as SurveyStateInitialized).currentQuestion]
  )

  // handle route changes (back button)
  useEffect(() => {
    router.events.on("routeChangeStart", handleRouteChange)
    return () => {
      router.events.off("routeChangeStart", handleRouteChange)
    }
  }, [handleRouteChange])

  //skip loader for funnels that don't need it
  useEffect(() => {
    if (skipLoaderFunnels.includes(funnel)) {
      setLoaderDone(true)
    }
  }, [funnel])

  const handleStepBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    ;(e.target as HTMLButtonElement).blur()
    dispatch({ type: "QUESTION_STEP_BACK" })
    document.getElementById("step-main-content").scrollTop = 0
  }

  const handleStepNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    ;(e.target as HTMLButtonElement).blur()
    dispatch({ type: "QUESTION_COMPLETED" })
    document.getElementById("step-main-content").scrollTop = 0
  }

  const onLoaderDone = () => {
    setLoaderDone(true)
  }

  //Possible loading screen images
  const themeImages = {
    foodscanner: { src: "/images/scanner_preview.gif", width: 300, height: 128, alt: "Food Scanner" },
    "women-fasting": {
      src: "/images/landingpage/women-fasting.png",
      width: 346,
      height: 328,
      alt: "Women Fasting",
    },
    tiktok: { src: "/images/landingpage/fasty_scanner.svg", width: 146, height: 128, alt: "Food Scanner" },
    "women-fasting2": {
      src: "/images/landingpage/women-fasting.png",
      width: 346,
      height: 328,
      alt: "Women Fasting",
    },
    "men-fasting": { src: "/images/landingpage/men-fasting.png", width: 346, height: 328, alt: "Men Fasting" },
    "beginners-keto": {
      src: "/images/landingpage/beginners-keto.png",
      width: 346,
      height: 328,
      alt: "Beginners Keto",
    },
    "women-keto": { src: "/images/landingpage/women-keto.png", width: 346, height: 328, alt: "Woman Keto" },
    "men-keto": { src: "/images/landingpage/men-keto.png", width: 346, height: 328, alt: "Men Keto" },
  }

  const defaultImage = {
    src: "/images/landingpage/weight-loss.svg",
    width: 640,
    height: 640,
    alt: "Default Weight Loss",
  }

  const imageProps = themeImages[surveyTheme as keyof typeof themeImages] || defaultImage

  if (!surveyState.initialized || !isLoaderDone || !statsigClient) {
    if (!routerInitDone || skipLoaderFunnels.includes(funnel)) {
      return (
        <>
          <Head>
            <title>{t("common:pagetitle")}</title>
          </Head>

          <Center>
            <LoadingSpinner />
          </Center>
        </>
      )
    }

    // Show full loader for all other cases
    return (
      <>
        <Head>
          <title>{t("common:pagetitle")}</title>
        </Head>
        <div className={"absolute inset-0 overflow-x-hidden flex flex-col"}>
          <SurveyHeader showBackButton={false} showNextButton={false} onStepBack={null} />
          <div id="step-main-content" className="flex-1 pb-3 relative">
            <div className="flex flex-col h-full max-w-xl w-full mx-auto px-4 justify-start items-center">
              <div className="grow items-center justify-center flex flex-col">
                <Image className="mb-4 max-h-52" {...imageProps} />
                <h2 className={classNames("text-center text-xl leading-tight font-bold text-boost1 mb-4 px-4")}>
                  {t("survey:loading.title", { context: surveyTheme })}
                </h2>
                <h3 className={classNames("text-center text-base leading-tight font-bold text-dark1 mb-4")}>
                  {t("survey:loading.subtitle", { context: surveyTheme })}
                </h3>
              </div>
              <div className="w-full">
                <div className="flex items-center justify-center">
                  <span className="text-base text-dark2">{t("survey:loading.loader")}</span>
                  <div className="ml-3">
                    <LoadingSpinner size="sm" />
                  </div>
                </div>
                <FakeProgressBar duration={3000} onEnd={onLoaderDone} />
              </div>
              {surveyTheme === "us" && (
                <div className="fixed bottom-0 w-full mx-auto flex flex-col items-center justify-center mb-8 text-xxs">
                  <p>Health Apps LLC, 2232 Dell Range Blvd Ste 200, Cheyenne, WY 82009</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderCurrentQuestion = () => {
    if (!surveyState.initialized || !surveyState.currentQuestion) return null

    const currentQuestion = surveyState.currentQuestion
    switch (currentQuestion.type) {
      case "select":
        return <SelectStep />
      case "custom":
        return currentQuestion.component
      default:
        return null
    }
  }

  const pageClassName = classNames(
    "absolute inset-0 overflow-x-hidden bg-[#FAF8F7] flex flex-col",
    surveyState.currentQuestion.pageClassName
  )

  const showBottomButton = !surveyState.currentQuestion.hideButtonBar && !surveyState.currentQuestion.completeOnSelect

  return (
    <StatsigProvider client={statsigClient}>
      <Head>
        <title>{t("common:pagetitle")}</title>
      </Head>
      <div className={pageClassName}>
        {!surveyState.currentQuestion.hideHeader && (
          <SurveyHeader
            showBackButton={surveyState.prevQuestion && !surveyState.currentQuestion.hideBackHeader}
            showNextButton={surveyState.currentQuestion.showNextHeader}
            onStepBack={surveyState.prevQuestion ? handleStepBack : null}
          />
        )}
        {!surveyState.currentQuestion.hideStepper && (
          <div className="w-full max-w-lg mx-auto px-4 flex items-center justify-center mb-8">
            <MilestoneStepsProgress
              steps={surveyState.milestoneStepper.totalSteps}
              current={surveyState.milestoneStepper.step}
              progress={surveyState.milestoneStepper.progress}
            />
          </div>
        )}

        <div id="step-main-content" className={classNames("flex-1 flex flex-col pb-3", showBottomButton && "mb-16")}>
          <div className="flex flex-col relative">
            <QuestionProvider question={surveyState.currentQuestion} answers={surveyState.answers} dispatch={dispatch}>
              {renderCurrentQuestion()}
            </QuestionProvider>
          </div>
        </div>

        {showBottomButton && (
          <div
            className={classNames(
              "fixed bottom-0 w-full py-3 md:pb-14",
              surveyTheme == "us" && "pb-14",
              surveyTheme == "outbrain" && "mb-12 md:mb-12"
            )}
          >
            <div className="flex items-center justify-center max-w-lg mx-auto px-4">
              <Button
                disabled={!surveyState.canStepNext}
                primary
                label={
                  surveyState.currentQuestion.buttonNextTitle
                    ? t(surveyState.currentQuestion.buttonNextTitle)
                    : t("common:controls.next")
                }
                className="w-full md:max-w-4/5"
                onClick={handleStepNext}
              />
            </div>
          </div>
        )}

        {surveyTheme === "us" && (
          <div className="w-full mx-auto flex flex-col items-center justify-center py-8 text-xxs">
            <p>Health Apps LLC, 2232 Dell Range Blvd Ste 200, Cheyenne, WY 82009</p>
          </div>
        )}
        {surveyTheme === "outbrain" && surveyState.currentQuestion.showFooter && (
          <div className="bg-white w-full overflow-hidden pt-6 md:pt-12 pb-7 relative z-10">
            <div className="px-5 mx-auto w-full max-w-4xl">
              <div className="flex flex-col text-left items-center">
                <div className="flex flex-col md:flex-row items-center justify-center gap-2 md:gap-0 text-sm text-gray-400">
                  <a
                    href={`https://fastic.com${i18n.language === "de" ? "/de" : "/en"}/impressum`}
                    className="text-gray-500 underline hover:text-gray-600"
                  >
                    {t("landing:footer.impressum.default")}
                  </a>
                  <span className="hidden md:block mx-2.5">|</span>
                  <a
                    href={`https://fastic.com${i18n.language === "de" ? "/de" : "/en"}/privacy-policy`}
                    className="text-gray-500 underline hover:text-gray-600"
                  >
                    {t("landing:footer.privacy_policy.default")}
                  </a>
                  <span className="hidden md:block mx-2.5">|</span>
                  <a
                    href={`https://fastic.com${i18n.language === "de" ? "/de" : "/en"}/terms`}
                    className="text-gray-500 underline hover:text-gray-600"
                  >
                    {t("landing:footer.terms.default")}
                  </a>
                </div>
                <p className="text-sm text-gray-400 mt-4 md:mt-2 text-center px-4">
                  {t("review:disclaimer.description")}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </StatsigProvider>
  )
}

export const getServerSideProps = (async (ctx) => {
  const country = ctx.req.headers["x-ip-country"] as string
  return {
    props: {
      geo: {
        country: country,
        currencyCode: ctx.req.headers["x-ip-currency-code"] as string,
      },
      statsig: await getStatsigServerProps(ctx, country),
      config: {
        fbPixelId:
          ctx.req.headers["x-ip-country"] === "US" ? "3891535701084994" : process.env.NEXT_PUBLIC_FB_PIXEL_ID || "",
      },
      ...(await serverSideTranslations(
        ctx.locale,
        includeDefaultNamespaces(["survey", "sign_in", "common", "account", "landing", "review"])
      )),
    },
  }
}) satisfies GetServerSideProps<{ geo: Geo; config: PageConfig; statsig: StatsigServerProps }>

export default withSubscription(withPayment(Survey))
